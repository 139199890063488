import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { Container } from 'components/primitives/grid';
import { useHasDeliveryDate } from '../basket/hooks';
import { requestUpdateDeliveryDate } from '../../../behavior/basket/actions';
import { toLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';

const DeliveryInfoBar = ({
  deliveryStoreName,
  deliveryDate,
  deliveryDates,
  requestedDeliveryDate,
}) => {
  const hasDeliveryDate = useHasDeliveryDate();
  const cultureName = useCultureName();
  const dispatch = useDispatch();

  const handleChange = async event => {
    dispatch(requestUpdateDeliveryDate(event.target.value));
  };

  if (!hasDeliveryDate) {
    return null;
  }

  if (!deliveryStoreName && !deliveryDate && !deliveryDates) 
return null;

  return (
    <div>
      <Container>
        <span>
          Deliver to: <u>{deliveryStoreName}</u> on{' '}
          <select onChange={handleChange}>
            {deliveryDates.map((dateItem, idx) => {
              if(dateItem === requestedDeliveryDate){
                return(
                <option key={idx} value={dateItem} selected>
                  {toLocaleDate(dateItem, cultureName)}
                </option>
                );
              } else{ 
                return (
                <option key={idx} value={dateItem}>
                  {toLocaleDate(dateItem, cultureName)}
                </option>
                );
              } 
            })}
          </select>
        </span>
      </Container>
    </div>
  );
};

DeliveryInfoBar.propTypes = {
  deliveryStoreName: PropTypes.string,
  deliveryDate: PropTypes.string,
  deliveryDates: PropTypes.array,
  requestedDeliveryDate: PropTypes.string,
};

export default connect(s => ({
  deliveryStoreName: s.user?.customer?.defaultDeliveryInfo?.deliveryStoreName,
  deliveryDate: s.user?.customer?.defaultDeliveryInfo?.storeNextDeliveryDate,
  deliveryDates: s.user?.customer?.defaultDeliveryInfo?.deliveryDates,
  requestedDeliveryDate: s?.basket?.requestedDeliveryDate,
}))(DeliveryInfoBar);
