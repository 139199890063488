import styles from './Summary.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { routesBuilder } from 'routes';
import { AbilityState } from 'behavior/user/constants';
import { abilityPropType } from 'behavior/user';
import { useRoutesChecker } from 'components/primitives/checkers';
import { RouteName } from 'routes';
import { DocumentType } from 'behavior/documents';
import FooterLink from './FooterLink';
import { useMinimumOrderValueMet, useHasDeliveryDate } from '../hooks';

const checkoutRoutes = [RouteName.Checkout];

const Footer = ({
  editDocumentType,
  createOrderAbility,
  isBlocked,
  createQuoteAbility,
  salesAgreementId,
  options,
}) => {
  const canSubmitOrder = createOrderAbility !== AbilityState.NotAvailable;
  const canCreateQuote = createQuoteAbility !== AbilityState.NotAvailable;
  const isCheckoutRoute = useRoutesChecker(checkoutRoutes);
  const basketLink = <FooterLink style={options.footerBasketLinkStyle} textKey="ViewBasket" to={routesBuilder.forBasket} />;

  /**
   * OCNSS-44 - get the minumum order value met from the basket summary and use it to conditionally set the checkout button
   */
  const minimumOrderValueMet = useMinimumOrderValueMet();
  const hasDeliveryDate = useHasDeliveryDate();

  if (isCheckoutRoute)
    return <div className={styles.panelFooter}>{basketLink}</div>;

  const isEditQuote = editDocumentType === DocumentType.Quote,
    isEditOrder = editDocumentType === DocumentType.Order;

  const checkoutLink = isEditOrder || canSubmitOrder && !isEditQuote
    ? (
      <FooterLink
        style={options.footerCheckoutLinkStyle}
        textKey="ButtonText_Checkout"
        to={routesBuilder.forCheckout}
        disabled={isBlocked || !minimumOrderValueMet || !hasDeliveryDate}
      />
    )
    : (isEditQuote || canCreateQuote) && !salesAgreementId ? (
      <FooterLink style={options.footerCheckoutLinkStyle} textKey="ButtonText_GetQuote" to={routesBuilder.forCheckout(true)} />
    ) : null;

  return (
    <div className={styles.panelFooter}>
      {options.swapFooterLinksPosition
        ? <>{checkoutLink}{basketLink}</>
        : <>{basketLink}{checkoutLink}</>
      }
    </div>
  );
};

Footer.propTypes = {
  editDocumentType: PropTypes.string,
  createOrderAbility: abilityPropType.isRequired,
  isBlocked: PropTypes.bool,
  createQuoteAbility: abilityPropType.isRequired,
  salesAgreementId: PropTypes.string,
  options: PropTypes.shape({
    footerBasketLinkStyle: PropTypes.number.isRequired,
    footerCheckoutLinkStyle: PropTypes.number.isRequired,
    swapFooterLinksPosition: PropTypes.bool.isRequired,
  }).isRequired,
};

export default memo(Footer);
