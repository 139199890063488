import { memo } from 'react';
import { UseSanaTexts } from 'components/sanaText';

/**
 * OCNSS-12 - Add the perCustMaxQtyExceeded array of preloaded texts - presumably for caching purposes
 */
const preloadTexts = [
  'ShoppingBasket_QuantityPostfix',
  'BasketSummary_AddingProduct',
  'ShoppingBasket_ProductAddedHeader',
  'SummaryPopup_Header',
  'Basket_Separator',
  'Basket_PerCustMaxQtyExceeded',
];

const None = () => null;
export default memo(() => <UseSanaTexts options={preloadTexts}>{None}</UseSanaTexts>);