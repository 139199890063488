import type { Epic } from 'behavior/types';
import type { Action } from 'redux';
import { ofType } from 'redux-observable';
import { mergeMap, startWith, exhaustMap, pluck } from 'rxjs/operators';
import { DOCUMENT_EDIT_REQUESTED, documentEditResultReceived, DocumentAction } from '../actions';
import { editMutation } from '../queries';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';
import { basketChangeCompleted, navigateTo } from 'behavior/events';
import { EditResult } from '../constants';

const epic: Epic<DocumentAction> = (action$, _state$, dependencies) =>
  action$.pipe(
    ofType(DOCUMENT_EDIT_REQUESTED),
    exhaustMap(({ payload }) =>
      dependencies.api.graphApi<EditResponse>(editMutation,
        { input: payload },
        { retries: 0 },
      ).pipe(
        pluck('documents', 'edit'),
        mergeMap(edit => {
          const result = edit && edit.result;

          const actions: Action[] = [
            unsetLoadingIndicator(),
          ];

          if (result === EditResult.Success) {
            // Non-zero linesUpdated signals that there was an actual basket change.
            actions.push(basketChangeCompleted(1, _state$.value.user?.customer?.defaultDeliveryInfo));
            actions.push(navigateTo(routesBuilder.forBasket()));
          } else {
            actions.push(documentEditResultReceived(result));
          }

          return actions;
        }),
        retryWithToast(action$, dependencies.logger),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

export default epic;

type EditResponse = {
  documents: {
    edit: {
      result: EditResult;
    } | null;
  };
};
