import { useSelector } from 'react-redux';

export const useIsQuickOrderMode = () => useSelector(({ basket }) => basket.isQuickOrderMode);

/**
 * OCNSS-44 - hooks to get the minimumOrderValueMet value from Redux
 */

export const useMinimumOrderValueMet = () => useSelector(({ basket: { minimumOrderValueMet } }) => minimumOrderValueMet);

export const useHasDeliveryDate = () => {

  const { customer } = useSelector(s => s.user);

  if (!(!!customer)) {
    return false;
  }
  
  const { defaultDeliveryInfo } = customer;

  if (!(!!defaultDeliveryInfo)) {
    return false;
  }

  const { storeNextDeliveryDate } = defaultDeliveryInfo;

  return !!storeNextDeliveryDate && 
    storeNextDeliveryDate!=='' &&
    storeNextDeliveryDate!=='0001-01-01';
}