import styles from './Summary.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { routesBuilder } from 'routes';
import { UomTitle } from 'components/primitives/product';
import { SimpleText } from 'components/sanaText';
import PriceNode from './PriceNode';
import Thumbnail from './Thumbnail';
import { AbilityState } from 'behavior/user/constants';
import { abilityPropType } from 'behavior/user';
import { useFormatNumberWithCulture } from 'utils/hooks';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';

const SummaryLine = ({
  productId,
  productTitle,
  productImage,
  productUrl,
  variantTitle,
  quantity,
  price,
  currencyInfo,
  uomId,
  uomDescription,
  viewUomAbility,
  showTotals,
  options,
  perCustMaxQtyExceeded,
}) => {
  const formatNumber = useFormatNumberWithCulture();
  const canViewUom = viewUomAbility === AbilityState.Available;
  const formattedQuantity = quantity && formatNumber(quantity);
  const route = routesBuilder.forProduct.bind(null, productId);

  /**
   * OCNSS-12 - Use the perCustMaxQtyExceeded prop to determine whether to show the perCustMaxQtyExceeded text
   */

  return (
    <div className={styles.productTile}>
      {options.showThumbnails && (
        <Thumbnail
          productTitle={productTitle}
          imageUrl={productImage && productImage.small}
          productUrl={productUrl}
          route={route}
        />
      )}
      <div className={styles.description}>
        {productUrl && (
          <WithProductClickTracking>
            {handleClick => (
              <Link onClick={handleClick} url={productUrl} to={route} className={styles.productTitle}>
                {productTitle}
              </Link>
            )}
          </WithProductClickTracking>
        )}
        {!productUrl && <span className={styles.productTitle}>{productTitle}</span>}
        {variantTitle && <div><span className={styles.variantTitle}>{variantTitle}</span></div>}
        <div className={styles.quantityPrice}>
          <div className={styles.quantity}>
            <span className={styles.lblQuantity}>{formattedQuantity}</span>
            {canViewUom && (
              <>
                {' '}
                <div className={styles.lblTextUom}>
                  <UomTitle id={uomId} description={uomDescription || ''} />
                </div>
              </>
            )}
            {!canViewUom && <span><SimpleText textKey="Basket_Separator" /></span>}
          </div>
          {showTotals && price != null && <div className={styles.price}><PriceNode price={price} currencyInfo={currencyInfo} /></div>}
        </div>
        {perCustMaxQtyExceeded && <div className={styles['per-cust-max-qty-exceeded-text']}><SimpleText textKey="Basket_PerCustMaxQtyExceeded" /></div>}
      </div>
    </div>
  );
};

SummaryLine.propTypes = {
  productId: PropTypes.string.isRequired,
  productUrl: PropTypes.string,
  productTitle: PropTypes.string,
  productImage: PropTypes.shape({
    small: PropTypes.string,
  }),
  variantTitle: PropTypes.string,
  uomId: PropTypes.string,
  uomDescription: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number,
  currencyInfo: PropTypes.object,
  viewUomAbility: abilityPropType.isRequired,
  showTotals: PropTypes.bool,
  options: PropTypes.shape({
    showThumbnails: PropTypes.bool.isRequired,
  }).isRequired,
  perCustMaxQtyExceeded: PropTypes.bool,
};

export default memo(SummaryLine);
