import { useSelector } from 'react-redux';
import { WarningAlert } from 'components/primitives/alerts';
import { useSanaTexts } from 'components/sanaText';
import { toLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';

const DeliveryDateChanged = () => {

  const cultureName = useCultureName();

  const {
    user: {
      isAuthenticated,
    },
    basket,
  } = useSelector(s => s);

  const [
    deliveryDateChangedTemplate,
  ] = useSanaTexts([
    'DeliveryDateChanged_Template',
  ]).texts;

  if (!isAuthenticated) {
    return null;
  }

  const { 
    requestedDeliveryDateChanged,
    summary,
  } = basket;

  if (!(!!summary)) {
    return null;
  }

  const { requestedDeliveryDate, totalCount } = summary;

  if (!(!!requestedDeliveryDate) || !requestedDeliveryDateChanged || !basket?.requestedDeliveryDate || !(!!totalCount)) {
    return null;
  }

  // const deliveryDateStr = toLocaleDate(new Date(requestedDeliveryDate), cultureName);
  const requestedDeliveryDateDateStr = toLocaleDate(new Date(basket?.requestedDeliveryDate), cultureName);

  const deliveryDateChangedMsg = deliveryDateChangedTemplate.replace('[DELIVERYDATE]', requestedDeliveryDateDateStr);

  return (
    <WarningAlert className="delivery-date-changed-warning">{deliveryDateChangedMsg}</WarningAlert>
  );
};

export default DeliveryDateChanged;